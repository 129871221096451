<template>
  <div class="realnameauth">
    <mt-header :title="$t('personalInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="1" />
    <div class="content">
      <mt-field
        :label="$t('firstName')"
        :placeholder="$t('firstNamePlaceholder')"
        :state="firstNameState"
        v-model="form.firstName"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C01_I_FIRSTNAME',
          newValue: form.firstName
        })"
      ></mt-field>
      <mt-field
        :label="$t('middleName')"
        :placeholder="$t('middleNamePlaceholder')"
        v-model="form.middleName"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C02_I_MIDDLENAME',
          newValue: form.middleName
        })"
      ></mt-field>
      <mt-field
        :label="$t('lastName')"
        :placeholder="$t('lastNamePlaceholder')"
        :state="lastNameState"
        v-model="form.lastName"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C03_I_LASTNAME',
          newValue: form.lastName
        })"
      ></mt-field>
      <mt-field
        :label="$t('idcard')"
        :placeholder="$t('idcardPlaceholder')"
        v-mask="'#### #### ###'"
        :state="idcardState"
        v-model="form.idcard"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C18_I_BVN',
          newValue: form.idcard
        })"
      ></mt-field>
      <mt-field
        :label="$t('email')"
        :placeholder="$t('emailPlaceholder')"
        :state="emailState"
        v-model="form.email"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C04_I_EMAIL',
          newValue: form.email
        })"
      ></mt-field>
      <mt-cell :label="$t('birthday')" class="cell" @click.native="onSelectDate">
        <div v-if="form.date" class="date">{{ form.date }}</div>
        <div v-else>{{ $t('birthdayPlaceholder') }}</div>
        <span class="mint-field-state" v-if="dateState" :class="['is-' + dateState]">
          <i class="mintui" :class="['mintui-field-' + dateState]"></i>
        </span>
        <i class="iconfont">&#xe62a;</i>
      </mt-cell>
      <mt-cell :label="$t('gender')" class="cell">
        <div>
          <span
            class="mint-radio-box"
            @click="form.sex = 'male'; BEHAVIOR_ADD({
              id: 'P01_C06_I_GENDER',
              newValue: form.sex
            })"
          >
            <span class="mint-radio">
              <input class="mint-radio-input" type="radio" v-model="form.sex" value="male">
              <span class="mint-radio-core"></span>
            </span>
            <span class="mint-radio-label">{{ $t('male') }}</span>
          </span>
          <span
            @click="form.sex = 'female'; BEHAVIOR_ADD({
              id: 'P01_C06_I_GENDER',
              newValue: form.sex
            })"
          >
            <span class="mint-radio">
              <input class="mint-radio-input" type="radio" v-model="form.sex" value="female">
              <span class="mint-radio-core"></span>
            </span>
            <span class="mint-radio-label">{{ $t('female') }}</span>
          </span>
        </div>
        <span class="mint-field-state" v-if="sexState" :class="['is-' + sexState]">
          <i class="mintui" :class="['mintui-field-' + sexState]"></i>
        </span>
      </mt-cell>
      <mt-field
        :label="$t('degree')"
        :placeholder="$t('degreePlaceholder')"
        readonly
        :state="educationCodeState"
        v-model="(Enum.educated.filter(e => e.code == form.educationCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.educated, (e) => {
          form.educationCode = e.code;
          BEHAVIOR_ADD({
            id: 'P01_C15_I_EDUCATION',
            newValue: form.educationCode
          })
        })"
      >
        <mt-spinner v-if="Enum.educatedLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('marriage')"
        :placeholder="$t('marriagePlaceholder')"
        readonly
        :state="maritalStatusState"
        v-model="(Enum.married.filter(e => e.code == form.maritalStatus)[0]||{}).name"
        @click.native="onPopupPicker(Enum.married, (e) => {
          form.maritalStatus = e.code;
          BEHAVIOR_ADD({
            id: 'P01_C07_I_MARITALSTATUS',
            newValue: form.maritalStatus
          })
        })"
      >
        <mt-spinner v-if="Enum.marriedLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('religion')"
        :placeholder="$t('religionPlaceholder')"
        readonly
        :state="religionCodeState"
        v-model="(Enum.religion.filter(e => e.code == form.religionCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.religion, (e) => {
          form.religionCode = e.code;
          BEHAVIOR_ADD({
            id: 'P01_C07_I_MARITALSTATUS',
            newValue: form.religionCode
          })
        })"
      >
        <mt-spinner v-if="Enum.religionLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('addressSelect1')"
        :placeholder="$t('addressPlaceholder')"
        readonly
        :state="homeAddrProvinceCodeState"
        v-model="(Enum.prov.filter(e => e.code == form.homeAddrProvinceCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.prov, (e) => {
          form.homeAddrProvinceCode = e.code;
          BEHAVIOR_ADD({
            id: 'P01_C08_I_STATE',
            newValue: form.homeAddrProvinceCode
          })
        })"
      >
        <mt-spinner v-if="Enum.provLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('addressSelect2')"
        :placeholder="$t('addressPlaceholder')"
        readonly
        :state="homeAddrCityCodeState"
        v-model="(Enum.city.filter(e => e.code == form.homeAddrCityCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.city, (e) => {
          form.homeAddrCityCode = e.code;
          BEHAVIOR_ADD({
            id: 'P01_C09_I_CITY',
            newValue: form.homeAddrCityCode
          })
        })"
      >
        <mt-spinner v-if="Enum.cityLoading" type="snake" color="#820ECA" :size="16"></mt-spinner>
        <i v-else class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('houseNo')"
        :placeholder="$t('houseNoPlaceholder')"
        :state="homeAddrDetailState"
        v-model="form.homeAddrDetail"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C10_I_DATAILEDADDRESS',
          newValue: form.homeAddrDetail
        })"
      ></mt-field>
      <mt-field
        :label="$t('backupPhone')"
        :placeholder="$t('backupPhonePlaceholder')"
        v-model="form.backupPhone"
        type="tel"
        v-mask="'#### #### #### #'"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C13_I_BACKUPPHONE',
          newValue: form.backupPhone
        })"
      ></mt-field>
      <mt-field
        :label="$t('whatsApp')"
        :placeholder="$t('whatsAppPlaceholder')"
        :state="whatsAppState"
        v-model="form.whatsApp"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C16_I_WHATSAPP',
          newValue: form.whatsApp
        })"
      ></mt-field>
      <mt-field
        :label="$t('facebook')"
        :placeholder="$t('facebookPlaceholder')"
        v-model="form.facebook"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P01_C17_I_FACEBOOK',
          newValue: form.facebook
        })"
      ></mt-field>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <mt-datetime-picker
      ref="picker"
      type="date"
      :value="new Date('1998-01-01')"
      :year-format="`{value} ${$t('year')}`"
      :month-format="`{value} ${$t('month')}`"
      :date-format="`{value} ${$t('date')}`"
      :start-date="new Date('1900-01-01')"
      :end-date="new Date()"
      @confirm="handleConfirm"
    >
    </mt-datetime-picker>
    <popup-picker ref="popupPicker" />

    <mt-popup v-model="checkPhoneVisible">
      <div class="box">
        <div class="title">
          {{ $t('prompt') }}
        </div>
        <div class="tips">
          {{ $t('selectPhoneTips') }}
        </div>
        <div class="val">
          <mt-radio
            v-model="checkPhone"
            :options="checkPhoneList">
          </mt-radio>
        </div>
        <div class="button">
          <mt-button type="primary" size="large" @click="submit">{{
            $t("submit")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
    
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { nameRules, idcardRules, emptyRules, emailRules } from "../../utils/rules";
import { step } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import Moment from 'moment'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
export default {
  name: 'Realnameauth',
  mixins: [mixin],
  components: {
    popupPicker,
    callPhone,
    stepItem,
  },
  data() {
    return {
      pickerVisible: false,
      isCheck: false,
      checkPhoneVisible: false,
      checkPhoneList: [],
      checkPhone: '', // 选择的手机号
      form: {
        firstName: '', // 名字
        middleName: '', // 中间名
        lastName: '', // 姓
        idcard: '', // 身份证
        email: '', // 邮箱
        date: '', // 生日
        sex: 'male', // 性别
        educationCode: '', // 学历
        maritalStatus: '', // 婚姻状况
        religionCode: '', // 宗教
        homeAddrProvinceCode: '', // 省市
        homeAddrCityCode: '', // 区
        homeAddrDetail: '', // 房屋号
        backupPhone: '', // 备用电话
        whatsApp: '',
        facebook: '',
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    firstNameState() {
      this.onStorageItem('firstName')
      return this.isCheck ? nameRules(this.form.firstName) : null;
    },
    lastNameState() {
      this.onStorageItem('lastName')
      return this.isCheck ? nameRules(this.form.lastName) : null;
    },
    idcardState() {
      this.onStorageItem('idcard')
      return this.isCheck ? idcardRules(this.form.idcard.replace(/\s/g, '')) : null;
    },
    emailState() {
      this.onStorageItem('email')
      return this.isCheck ? emailRules(this.form.email) : null;
    },
    dateState() {
      this.onStorageItem('date')
      return this.isCheck ? emptyRules(this.form.date) : null;
    },
    sexState() {
      this.onStorageItem('sex')
      return this.isCheck ? emptyRules(this.form.sex) : null;
    },
    educationCodeState() {
      this.onStorageItem('educationCode')
      return this.isCheck ? emptyRules(this.form.educationCode) : null;
    },
    maritalStatusState() {
      this.onStorageItem('maritalStatus')
      return this.isCheck ? emptyRules(this.form.maritalStatus) : null;
    },
    religionCodeState() {
      this.onStorageItem('religionCode')
      return this.isCheck ? emptyRules(this.form.religionCode) : null;
    },
    homeAddrProvinceCodeState() {
      this.onStorageItem('homeAddrProvinceCode')
      return this.isCheck ? emptyRules(this.form.homeAddrProvinceCode) : null;
    },
    homeAddrCityCodeState() {
      this.onStorageItem('homeAddrCityCode')
      return this.isCheck ? emptyRules(this.form.homeAddrCityCode) : null;
    },
    homeAddrDetailState() {
      this.onStorageItem('homeAddrDetail')
      return this.isCheck ? emptyRules(this.form.homeAddrDetail) : null;
    },
    whatsAppState() {
      this.onStorageItem('whatsApp')
      return this.isCheck ? emptyRules(this.form.whatsApp) : null;
    }
  },
  watch: {
    'form.middleName': {
      handler() {
        this.onStorageItem('middleName')
      }
    },
    'form.backupPhone': {
      handler() {
        this.onStorageItem('backupPhone')
      }
    },
    'form.facebook': {
      handler() {
        this.onStorageItem('facebook')
      }
    },
    // 监听省市发生变化重置区内容
    'form.homeAddrProvinceCode': {
      handler(val) {
        if (!val) return
        this.GET_DISTRICT_CITY(val).then(() => {
          let length = this.Enum.city.filter(e => e.code == this.form.homeAddrCityCode).length
          if (!length) {
            this.form.homeAddrCityCode = ''
          }
        })
      },
      immediate: true
    },
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      if (this.apply[val]) {
        this.form[val] = this.apply[val]
      }
    })
    if (!this.form.sex) {
      this.form.sex = 'male'
    }
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P01_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P01_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_EDUCATION() // 获取学历
      this.GET_MARITAL_STATUS() // 获取婚姻状况
      this.GET_DISTRICT_PROV() // 获取省市
      this.GET_RELIGION() // 获取宗教
    },
    // 选择生日
    onSelectDate() {
      this.$refs.picker.open();
    },
    handleConfirm(date) {
      this.form.date = Moment(date).format('YYYY-MM-DD')
      this.BEHAVIOR_ADD({
        id: 'P01_C05_I_DATEBIRTH',
        newValue: this.form.date
      })
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('firstName') >= 0 && this.firstNameState != "success") {
        return this.$t('firstNamePlaceholder')
      }
      if (list.indexOf('lastName') >= 0 && this.lastNameState != "success") {
        return this.$t('lastNamePlaceholder')
      }
      if (list.indexOf('idcard') >= 0 && this.idcardState != "success") {
        return this.idcardState == 'warning'? this.$t('idcardPlaceholder'): this.$t('idcardError')
      }
      if (list.indexOf('email') >= 0 && this.emailState != "success") {
        return this.emailState == 'warning'? this.$t('emailPlaceholder'): this.$t('emailError')
      }
      if (list.indexOf('date') >= 0 && this.dateState != "success") {
        return this.$t('birthdayError')
      }
      if (list.indexOf('sex') >= 0 && this.sexState != "success") {
        return this.$t('genderError')
      }
      if (list.indexOf('educationCode') >= 0 && this.educationCodeState != "success") {
        return this.$t('degreeError')
      }
      if (list.indexOf('maritalStatus') >= 0 && this.maritalStatusState != "success") {
        return this.$t('marriageError')
      }
      if (list.indexOf('religionCode') >= 0 && this.religionCodeState != "success") {
        return this.$t('religionError')
      }
      if (list.indexOf('homeAddrProvinceCode') >= 0 && this.homeAddrProvinceCodeState != "success") {
        return this.$t('addressError')
      }
      if (list.indexOf('homeAddrCityCode') >= 0 && this.homeAddrCityCodeState != "success") {
        return this.$t('addressError')
      }
      if (list.indexOf('homeAddrDetail') >= 0 && this.homeAddrDetailState != "success") {
        return this.homeAddrDetailState == 'warning'? this.$t('houseNoPlaceholder'): this.$t('houseNoError')
      }
      if (list.indexOf('whatsApp') >= 0 && this.whatsAppState != "success") {
        return this.whatsAppState == 'warning'? this.$t('whatsAppPlaceholder'): this.$t('whatsAppError')
      }
    },
    // 提交下一步
    async submit() {
      this.BEHAVIOR_ADD({
        id: 'P01_C98_B_SUBMIT'
      })
      this.isCheck = true;
      var error = this.validation([
        'firstName',
        'lastName',
        'idcard',
        'email',
        'date',
        'sex',
        'educationCode',
        'maritalStatus',
        'religionCode',
        'homeAddrProvinceCode',
        'homeAddrCityCode',
        'homeAddrDetail',
        'whatsApp',
      ]);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('02_REAL_NAME_AUTH', { content_name: '02_REAL_NAME_AUTH' })
        }
        // Google Analytics  REAL_NAME_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'REAL_NAME_AUTH')
        }
      } catch(e) {
        console.log(e)
      }
      var thirdInfos = []
      if (this.form.whatsApp) {
        thirdInfos.push({
          "authPhone": this.form.whatsApp,
          "authType": "",
          "isAuth": "Y",
          "thirdCode": "whatsApp",
          "thirdName": "whatsApp"
        })
      }
      if (this.form.facebook) {
        thirdInfos.push({
          "authPhone": this.form.facebook,
          "authType": "",
          "isAuth": "Y",
          "thirdCode": "Facebook",
          "thirdName": "Facebook"
        })
      }
      let data = {
        authName: "REALNAME_AUTH",
        
        homeAddrProvince: (this.Enum.prov.filter(e => e.code == this.form.homeAddrProvinceCode)[0]||{}).name,
        homeAddrCity: (this.Enum.city.filter(e => e.code == this.form.homeAddrCityCode)[0]||{}).name,
        religionValue: (this.Enum.religion.filter(e => e.code == this.form.religionCode)[0]||{}).name,

        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),
        applyId: this.user.applyId,
        ...this.form,
        idcard: this.form.idcard.replace(/\s/g, ''),
        backupPhone: this.form.backupPhone.replace(/\s/g, ''),
        thirdInfos,
        currentStep: 2,
        totalSteps: 7,
        name: `${this.form.firstName} ${this.form.middleName} ${this.form.lastName}`,
        email: this.form.email.replace(/\s/g, ''),
      }
      if (this.checkPhone) {
        data['checkPhone'] = this.checkPhone
      }
      await this.$axios({
        method: "post",
        url: step,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/jobauth')
          } else if (e.status.code == '928') {
            this.checkPhoneList = e.body
            if (e.body.length) {
              this.checkPhone = e.body[0]
            }
            this.checkPhoneVisible = true
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('02_REAL_NAME_AUTH', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.realnameauth {
  .content {
    .cell {
      .date {
        color: black;
      }
      /deep/ .mint-cell-label {
        font-size: 16px;
        color: inherit;
      }
      /deep/ .mint-cell-title {
        width: 105px;
        flex: none;
      }
      /deep/ .mint-cell-value {
        padding: 0 2px;
        color: #818181;
        flex: 1;
        display: flex;
        div {
          flex: 1;
        }
      }
    }
    .iconfont {
      margin: 0 5px;
      color: black;
    }
    .mint-radio-box {
      margin-right: 20px;
    }
  }
  .bottom {
    padding: 40px 20px 20px 20px;
  }
  .box {
    padding: 10px 15px;
    background-color: white;
    width: 300px;
    .title {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 10px;
    }
    .tips {
      font-weight: 600;
      padding: 0;
    }
    .val {
      font-size: 14px;
      div {
        padding: 5px 0px;
      }
    }
    .button {
      padding: 10px;
      display: flex;
      button {
        margin: 0px 10px;
      }
    }
  }
}
</style>